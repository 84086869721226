import Header from './Inc/Header';
import Footer from './Inc/Footer';
import Hero from './Components/Hero';
import About from './Components/About';
import Services from './Components/Services';
import Team from './Components/Team';
import Contact from './Components/Contact';


import './Styles/style.css';
import './Styles/input.css';
import './Styles/output.css';

function App() {
  return (
    <div className="App">
      <Header />
      <Hero />
      <About />
      <Services />
      {/* <Team /> */}
      <Contact />
      <Footer />
    </div>
  );
}

export default App;
