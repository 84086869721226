import React from 'react';

function Footer() {
    return (
        <footer class="bg-indigo-600  dark:bg-gray-800 md:mt-4 lg:mt-4 ">
            <div class="max-w-screen-xl p-4 py-3 mx-auto lg:py-3 md:p-3 lg:p-3 ">
                <div class="text-center ">
                    <span class="block text-sm text-center text-white dark:text-gray-400 "> &hearts; monkworks Pvt. Ltd.
                    </span>
                </div>
            </div>
        </footer >
    )
}

export default Footer;