import React from 'react';

function Contact() {
    return (
        <section id="contact" class="relative py-5 md:py-[120px]">
            <div class="absolute top-0 left-0 z-[-1] h-1/2 w-full bg-[#f3f4fe] lg:h-[45%] xl:h-1/2"></div>
            <div class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                <div class="-mx-4 flex flex-wrap items-center">
                    <div class="w-full px-4 lg:w-7/12 xl:w-8/12">
                        <div class="ud-contact-content-wrapper">
                            <div class="ud-contact-title mb-12 lg:mb-[150px]">
                                <span class="mb-5 text-base font-semibold text-dark">
                                    <span class="title z-10"> CONTACT US </span>
                                </span>
                                <p class="text-lg font-medium text-purple-600 dark:text-purple-500">Let's Start a New Project Together
                                </p>
                                <h2 class="text-[35px] font-Poppins font-extrabold">
                                    Take Your Business To The <span class="underline decoration-c2">Next Level</span>
                                </h2>
                            </div>
                            <div class="mb-12 flex flex-wrap justify-between lg:mb-0">
                                <div class="mb-8 flex w-[330px] max-w-full">
                                    <div class="mr-6 text-[32px] text-primary">


                                    </div>
                                    <div>
                                        <h5 class="mb-4 text-lg font-Poppins font-semibold">Call Us</h5>
                                        <p class="text-base text-gray-500"> +91 73388 80234</p>
                                    </div>
                                </div>
                                <div class="mb-8 flex w-[330px] max-w-full">
                                    <div class="mr-6 text-[32px] text-primary">


                                    </div>
                                    <div>
                                        <h5 class="mb-4 text-lg font-Poppins font-semibold">Email Us</h5>
                                        <p class="text-base text-gray-500">hello@monkworks.com</p>
                                    </div>
                                </div>
                            </div>
                            <div class="mb-12 flex flex-wrap justify-between lg:mb-0">
                                <div class="mb-8 flex w-[330px] max-w-full">
                                    <div class="mr-6 text-[32px] text-primary">



                                    </div>
                                    <div>
                                        <h5 class="mb-4 text-lg font-Poppins font-semibold">Follow Us</h5>
                                        <ul class="flex space-x-5 ">
                                            <li>
                                                <a href="# " class="text-gray-500 hover:text-c3 dark:hover:text-white dark:text-gray-400 ">
                                                    <svg class="w-5 h-5 " fill="currentColor " viewBox="0 0 24 24 " aria-hidden="true ">
                                                        <path fill-rule="evenodd " d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443
                                                    2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z "
                                                            clip-rule="evenodd " /></svg>
                                                </a>
                                            </li>
                                            <li>
                                                <a href="# " class="text-gray-500 hover:text-c3 dark:hover:text-white dark:text-gray-400 ">
                                                    <svg class="w-5 h-5 " fill="currentColor " viewBox="0 0 24 24 " aria-hidden="true ">
                                                        <path fill-rule="evenodd " d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06
                                                    4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902
                                                    4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0
                                                    015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344
                                                    1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01
                                                    3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097
                                                    0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2
                                                    1.2 0 110 2.4 1.2 1.2 0 010-2.4z " clip-rule="evenodd " /></svg>
                                                </a>
                                            </li>
                                            <li>
                                                <a href="# " class="text-gray-500 hover:text-c3 dark:hover:text-white dark:text-gray-400 ">
                                                    <svg class="w-5 h-5 " fill="currentColor " viewBox="0 0 24 24 " aria-hidden="true ">
                                                        <path
                                                            d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65
                                                    11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84 " />
                                                    </svg>
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="mb-8 flex w-[330px] max-w-full">
                                    <div class="mr-6 text-[32px] text-primary">
                                        <svg width="29" height="35" viewBox="0 0 29 35" class="fill-current">
                                            <path
                                                d="M14.5 0.710938C6.89844 0.710938 0.664062 6.72656 0.664062 14.0547C0.664062 19.9062 9.03125 29.5859 12.6406 33.5234C13.1328 34.0703 13.7891 34.3437 14.5 34.3437C15.2109 34.3437 15.8672 34.0703 16.3594 33.5234C19.9688 29.6406 28.3359 19.9062 28.3359 14.0547C28.3359 6.67188 22.1016 0.710938 14.5 0.710938ZM14.9375 32.2109C14.6641 32.4844 14.2812 32.4844 14.0625 32.2109C11.3828 29.3125 2.57812 19.3594 2.57812 14.0547C2.57812 7.71094 7.9375 2.625 14.5 2.625C21.0625 2.625 26.4219 7.76562 26.4219 14.0547C26.4219 19.3594 17.6172 29.2578 14.9375 32.2109Z">
                                            </path>
                                            <path
                                                d="M14.5 8.58594C11.2734 8.58594 8.59375 11.2109 8.59375 14.4922C8.59375 17.7188 11.2187 20.3984 14.5 20.3984C17.7812 20.3984 20.4062 17.7734 20.4062 14.4922C20.4062 11.2109 17.7266 8.58594 14.5 8.58594ZM14.5 18.4297C12.3125 18.4297 10.5078 16.625 10.5078 14.4375C10.5078 12.25 12.3125 10.4453 14.5 10.4453C16.6875 10.4453 18.4922 12.25 18.4922 14.4375C18.4922 16.625 16.6875 18.4297 14.5 18.4297Z">
                                            </path>
                                        </svg>
                                    </div>
                                    <div>
                                        <h5 class="mb-4 text-lg font-Poppins font-semibold">Meet Us</h5>
                                        <p class="text-base text-gray-500">
                                            Chennai, Tamil Nadu, India
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="w-full px-4 lg:w-5/12 xl:w-4/12">
                        <div class="wow fadeInUp rounded-lg bg-white py-10 px-8 shadow-2xl sm:py-12 sm:px-10 md:p-[60px] lg:p-10 lg:py-12 lg:px-10 2xl:p-[60px]" data-wow-delay=".2s" style={{ visibility: 'visible', animationDelay: '0.2s' }}>
                            <h3 class="mb-8 text-2xl text-center font-semibold md:text-[26px]">
                                We love to hear from you!
                            </h3>
                            <form>
                                <div class="mb-6">
                                    <label for="fullName" class="block text-xs text-dark">Full Name*</label>
                                    <input type="text" name="fullName" placeholder="" class="w-full border-0 border-b border-[#f1f1f1] py-4 focus:border-primary focus:outline-none" />
                                </div>
                                <div class="mb-6">
                                    <label for="email" class="block text-xs text-dark">Email*</label>
                                    <input type="email" name="email" placeholder=" " class=" w-full border-0 border-b border-[#f1f1f1] py-4 focus:border-primary focus:outline-none " />
                                </div>
                                <div class="mb-6 ">
                                    <label for="phone " class="block text-xs text-dark ">Phone*</label>
                                    <input type="text " name="phone " placeholder=" " class="w-full border-0 border-b border-[#f1f1f1] py-4 focus:border-primary focus:outline-none " />
                                </div>
                                <div class="mb-6 ">
                                    <label for="message " class="block text-xs text-dark ">Message*</label>
                                    <textarea name="message " rows="1 " placeholder=" " class="w-full resize-none border-0 border-b border-[#f1f1f1] py-4 focus:border-primary focus:outline-none "></textarea>
                                </div>
                                <div class="mb-0 text-center">
                                    <button type="submit " class="inline-flex bg-indigo-600 items-center justify-center rounded bg-primary py-4 px-6 text-base font-medium text-white transition duration-300 ease-in-out hover:bg-dark ">
                                        Send Message
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Contact;