import React from 'react';

function Services() {
    return (
        <section id="services" class="bg-gray-50 dark:bg-gray-900 border border-solid	border-2 border-t-from-indigo-500 via-purple-500 to-pink-500 border-from-indigo-500 via-purple-500 to-pink-500">
            <div class="max-w-screen-xl px-4 py-8 mx-auto lg:py-24 lg:px-6">
                <div class="max-w-screen-md mx-auto mb-8 text-center lg:mb-12">
                    <span class="title"> SERVICES </span>
                    <p class="text-lg font-medium text-c1 dark:text-purple-500">Complete IT solution for all your business needs
                    </p>
                    <h2 class="mb-4 text-4xl font-Poppins font-extrabold text-c3 dark:text-white">From Strategy To Execution </h2>
                    <p class="mb-5 font-light sm:text-xl dark:text-gray-400">we offer a wide range of services to help our clients achieve their goals and solve their business challenges. Our team is composed of experts in a variety of fields, and we are committed to delivering the best possible solutions for our
                        clients.

                    </p>
                </div>
                <div class="space-y-8 lg:grid lg:grid-cols-3 sm:gap-6 xl:gap-10 lg:space-y-0 ">
                    <div class=" flex flex-col max-w-lg p-6 mx-auto text-center text-c3 bg-white border border-gray-100 rounded-lg shadow dark:border-gray-600 xl:p-8 dark:bg-gray-800 dark:text-white shadow-lg shadow-indigo-500/40 ">



                        <h3 class="mb-4 text-2xl font-Poppins font-semibold">Mobile App Development</h3>

                        <p class="font-light text-justify text-gray-500 sm:text-lg dark:text-gray-400">We specialize in developing high-quality mobile apps that are tailored to the unique needs of our clients. Our team of experienced developers is skilled in a variety of programming languages and frameworks, and we use the latest tools
                            and technologies to deliver the best possible results.</p>
                        <div class="grid grid-cols-3 mt-4 divide-x">
                            <div></div>
                            <div></div>
                            <div></div>
                        </div>
                    </div>
                    <div class="flex flex-col max-w-lg p-6 mx-auto text-center text-c3 bg-white border border-gray-100 rounded-lg shadow dark:border-gray-600 xl:p-8 dark:bg-gray-800 dark:text-white shadow-lg shadow-indigo-500/40 ">


                        <h3 class="mb-4 text-2xl font-Poppins font-semibold">Web App Development</h3>
                        <p class="text-base font-light  text-justify text-gray-500 sm:text-lg dark:text-gray-400">Whether you're looking to develop a new web app from scratch or to upgrade an existing app, we have the expertise and experience to help you succeed. We use a user-centered approach to design, and we focus on creating web apps that
                            are intuitive, user-friendly, and visually appealing.</p>
                    </div>
                    <div class="flex flex-col max-w-lg p-6 mx-auto text-center text-c3 bg-white border border-gray-100 rounded-lg shadow dark:border-gray-600 xl:p-8 dark:bg-gray-800 dark:text-white shadow-lg shadow-indigo-500/40 ">


                        <h3 class="mb-4 text-2xl font-Poppins font-semibold">E-Commerce Business</h3>
                        <p class="font-light text-justify text-gray-500 sm:text-lg dark:text-gray-400">We are providing ecommerce services that can help businesses to reach a wider audience, to improve their sales and revenue, and to better meet the needs of their customers. It can also help to improve their efficiency and competitiveness
                            in the marketplace.</p>
                    </div>
                    <div class="flex flex-col max-w-lg p-6 mx-auto text-center text-c3 bg-white border border-gray-100 rounded-lg shadow dark:border-gray-600 xl:p-8 dark:bg-gray-800 dark:text-white shadow-lg shadow-indigo-500/40 ">


                        <h3 class="mb-4 text-2xl font-Poppins font-semibold">Graphic Designs</h3>
                        <p class="font-light text-justify text-gray-500 sm:text-lg dark:text-gray-400"> We can create a range of materials, such as brochures, posters, and business cards, that will help you to promote your products and services. Web graphics: We can design and develop graphics for your website, including banners, icons,
                            and buttons, that will enhance the user experience and improve your site's appeal.</p>
                    </div>
                    <div class="flex flex-col max-w-lg p-6 mx-auto text-center text-c3 bg-white border border-gray-100 rounded-lg shadow dark:border-gray-600 xl:p-8 dark:bg-gray-800 dark:text-white shadow-lg shadow-indigo-500/40 ">


                        <h3 class="mb-4 text-2xl font-Poppins font-semibold">Payments Integrations</h3>
                        <p class="font-light text-justify text-gray-500 sm:text-lg dark:text-gray-400">We can integrate with online payment services, such as <b>PayPal and Stripe,</b> to provide additional options for customers who prefer to use these services for online payments. We can integrate with major credit and debit card providers,
                            such as <b>Visa, Mastercard, and American Express, </b>to allow customers to easily and securely make online payments.</p>
                    </div>
                    <div class="flex flex-col max-w-lg p-6 mx-auto text-center text-c3 bg-white border border-gray-100 rounded-lg shadow dark:border-gray-600 xl:p-8 dark:bg-gray-800 dark:text-white shadow-lg shadow-indigo-500/40 ">


                        <h3 class="mb-4 text-2xl font-Poppins font-semibold">Digital Marketing</h3>
                        <p class="font-light text-slate-700 text-justify text-gray-500 sm:text-lg dark:text-gray-400">We use social media platforms, such as <b> Facebook, Twitter, and LinkedIn</b> to create and manage campaigns that can help our clients to build their brand, to engage with their audience, and to drive more traffic and conversions.
                            We use PPC platforms, such as <b> Google AdWords,</b> to create and manage targeted advertising campaigns that can help our clients to reach a wider audience and to drive more traffic to their websites.
                        </p>
                    </div>
                </div>
            </div>
        </section >
    )
}

export default Services