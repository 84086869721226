import React from 'react';
import { SiMulesoft } from "react-icons/si";
import heroImage from '../Images/hero.jpg';
import { FaReact } from "react-icons/fa";
import { FaNodeJs } from "react-icons/fa";
import { HiOutlineShoppingCart } from "react-icons/hi";
import { MdOutlinePhoneIphone } from "react-icons/md";
import { LuMail } from "react-icons/lu";

function Hero() {
    return (
        <section id="home" >

            <div className="bg-gradient-to-r from-c5lite to-white dark:bg-gray-900">

                <div className="grid max-w-screen-xl px-4 pt-20 pb-8 mx-auto lg:gap-8 xl:gap-0 lg:py-16 lg:grid-cols-12 lg:pt-28 lg:pt-30 items-center h-full">
                    <div className="mr-auto place-self-center lg:col-span-7 lg:mt-8 lg:ml-16">
                        <h2 className="max-w-2xl mb-4 text-4xl font-extrabold leading-none text-c3 tracking-tight md:text-5xl xl:text-5xl dark:text-white">
                            <span className="mb-4 block">WEB.</span>
                            <span className="mb-4 block">MOBILE.</span>
                            <span className="mb-4 block">GRAPHIC.</span>
                            <span className="mb-4 block">MARKETING.</span>
                        </h2>
                        <p className="max-w-2xl text-justify  tracking-normal mt-8 mb-6 font-light text-gray-500 lg:mb-8 md:text-lg lg:text-xl dark:text-gray-400">
                            At monkworks, we are passionate about using technology to solve real-world problems and drive positive change. With a dedicated team of experts, we offer a comprehensive suite of services tailored to meet the unique needs of our clients. From innovative software solutions to cutting-edge digital strategies, we are committed to helping businesses thrive in today's dynamic landscape.
                        </p>
                        <div className="space-y-4 sm:flex sm:space-y-0 sm:space-x-4">

                            <a className="group relative inline-flex  focus:outline-none focus:ring" href="/download">

                                <span className="relative inline-flex border-2 items-center border-current px-8 py-3 text-sm font-bold uppercase tracking-normal text-black group-active:text-opacity-75">
                                    <LuMail className='mr-2' />
                                    GET A QUICK QUOTE
                                </span>
                            </a>

                            <a className="group relative inline-flex  focus:outline-none focus:ring" href="/download">

                                <span className="relative inline-flex border-2 items-center border-current px-8 py-3 text-sm font-bold uppercase tracking-normal text-black group-active:text-opacity-75">
                                    <MdOutlinePhoneIphone className='mr-2' />
                                    SCHEDULE A CALL
                                </span>
                            </a>

                        </div>
                    </div>
                    <div className="hidden lg:mt-0 lg:col-span-5 lg:flex">
                        <section className="container">
                            <div className="main flex items-center justify-center h-full">
                                <div className="rounding-sec">
                                    <div className="big-circle">
                                        <div className="icon-block">
                                            <FaReact />
                                        </div>
                                        <div className="icon-block">
                                            <FaNodeJs />
                                        </div>
                                        <div className="icon-block">
                                            <SiMulesoft />
                                        </div>
                                        <div className="icon-block">
                                            <HiOutlineShoppingCart />
                                        </div>
                                    </div>
                                    <div className="images">
                                        <img src={heroImage} alt="" className="img-w" />
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>

            </div>


            <div class="w-full draggable mt-8">
                <div class="container flex flex-col items-center gap-8 mx-auto my-32">
                    <p class="text-base font-medium leading-7 text-center text-gray-500">Inspiring the growth of small businesses worldwide</p>
                    <div class="flex flex-wrap items-center justify-center w-full gap-6 lg:gap-0 lg:flex-nowrap lg:justify-between">
                        <span>
                            <img src="https://rainorshinesivakasicrackers.in/thing/view/image/logo.png" width="200" height="50" />
                        </span>
                    </div>
                </div>
            </div>


        </section>
    )
}

export default Hero;